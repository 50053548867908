<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="card">
        <div class="card-block">
          <div class="h4 m-0">{{ $t('general.step1') }}</div>
          <small class="text-muted">{{ $t('general.basicParameters') }}</small>
          <span class="h6 setting-title">{{ $t('GuestControl.ruleSettings') }}</span>

          <GeneralSettings :redirectRuleData="newRedirectRuleData" :isWizard="true"></GeneralSettings>

          <div class="mt-2">
            <button
              type="button"
              class="btn btn-outline-primary float-right cpe-register-next-button"
              @click="setNewRedirectRuleData"
              :disabled="errors.any() || (newRedirectRuleData.redirect_url && !newRedirectRuleData.url_list.length)"
            >
              {{ $t('general.next') }}
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary float-left cpe-register-back-button"
              @click="$parent.prevStep"
            >
              {{ $t('general.back') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralSettings from '../../components/GuestControl/generalSettings';
import commonService from '../../services/commonService';

export default {
  components: { GeneralSettings },
  name: 'GuestControlStep1',
  data() {
    return {
      newRedirectRuleData: {
        name: '',
        redirect_url: ''
      }
    };
  },
  computed: {
    commonService() {
      return commonService;
    }
  },
  methods: {
    setNewRedirectRuleData() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            this.$store.commit('setNewRedirectRuleData', this.newRedirectRuleData);
            this.$parent.nextStep();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    }
  },
  created() {
    this.newRedirectRuleData = JSON.parse(JSON.stringify(this.$store.state.newRedirectRuleData));
    // this.newRedirectRuleData.base_location = this.$store.state.userData.base_location;
    // делаем пустым по умолчанию. Задачка WNE-2308
    this.newRedirectRuleData.base_location = '';
  }
};
</script>
<style lang="scss" scoped></style>
